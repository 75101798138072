import React, { useEffect, useMemo, useRef, useState } from 'react';
import Lottie from 'lottie-react';

import { triggerCustomEvent } from '@helpers/miscelleanous';
import { getFormattedHomepageBanners } from '@helpers/homepageData';
import { HOMEPAGE_BANNER_CAROUSEL_AUTOPLAY_INTERVAL } from '@helpers/constants';

import {
    Carousel,
    CarouselContent,
    CarouselDots,
    CarouselItem,
} from '@molecules/Carousel';
import { Link, Text, Image } from '@ui/atoms';

import scrollDown from '@public/assets/scroll-down.json';
import texts from '@components/Homepage/en.json';
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import useIntersection from '@ui/hooks/useIntersection';


const CarouselBanner = ({ homepageBanners }) => {
    const { width } = useWindowDimensions();
    const ref = useRef(null);
    const isLottileVisible = useIntersection(ref, '-40px');
    const persistedBanners = useMemo(() => {
        const formattedHomepageBanners = getFormattedHomepageBanners(
            homepageBanners,
            width < 768
        );
        return formattedHomepageBanners;
    }, [width < 768]);

    const handleScrollDownClick = () => {
        const investmentStatsSection =
            document.getElementById('opportunitiesSection');
        if (investmentStatsSection) {
            investmentStatsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleGtmTrigger = (trigger) => {
        try {
            triggerCustomEvent(trigger);
        } catch (error) {
            console.error('Error triggering GA event:', error);
        }
    };

    return (
        <div className="relative flex flex-col items-center max-w-full px-4 pt-12 pb-6 mx-auto md:pb-12 2xl:max-w-screen-maxScreen md:px-24 md:justify-center mt-header-mobile md:mt-header xl:min-h-calc-vh-home-page-banner-web">
            <div className="max-w-[942px] md:max-w-none w-full h-full mx-auto flex flex-col gap-6 md:gap-10 xl:gap-8 2xl:gap-12">
                <div className="mx-auto">
                    <Text
                        content={texts?.bannerCarouselTitle}
                        className="text-center h4-semibold md:h3-semibold text-primary-500"
                        isInnerHtml
                        htmlTag="h1"
                    />
                </div>
                <Carousel
                    opts={{
                        loop: true,
                    }}
                    autoPlay={true}
                    count={persistedBanners?.length || 1}
                    delay={HOMEPAGE_BANNER_CAROUSEL_AUTOPLAY_INTERVAL}
                    className="w-full h-full overflow-hidden rounded-lg"
                >
                    <CarouselContent className="md:min-h-[400px] xl:min-h-[320px]  min-h-[288px] 2xl:min-h-full w-full gap-4">
                        {persistedBanners?.length > 0 ? (
                            persistedBanners?.map((item, index) => (
                                <CarouselItem
                                    key={index}
                                    className="w-full h-full pl-0 "
                                >
                                    <Link
                                        href={item?.redirectLink || '#'}
                                        target={item.target}
                                        onClick={() => {
                                            handleGtmTrigger(item.gtmTrigger);
                                        }}
                                        className="flex items-center cursor-pointer justify-center w-full h-full md:min-w-[492px] xl:max-w-[994px] xl:max-h-[356px] 2xl:min-w-[994px] 2xl:max-w-none 2xl:max-h-full mx-auto"
                                    >
                                        {width >= 1280 ? (
                                            <Image
                                                src={item.webLink}
                                                alt="hero-banner"
                                                className="hidden object-cover w-full h-full overflow-hidden rounded-lg xl:inline-block"
                                                title={item.description}
                                            />
                                        ) : (
                                            <Image
                                                src={item.mobileLink}
                                                alt="hero-banner"
                                                className="inline-block object-cover w-full h-full rounded-lg xl:hidden"
                                                title={item.description}
                                            />
                                        )}
                                    </Link>
                                </CarouselItem>
                            ))
                        ) : (
                            <CarouselItem className="w-full h-full pl-0 !ml-0 rounded-xl">
                                <div className="min-w-[344px] mx-auto w-full h-full bg-gray-50 min-h-[350px] flex justify-center items-center">
                                    <div className="button-loader-primary" />
                                </div>
                            </CarouselItem>
                        )}
                    </CarouselContent>
                    <CarouselDots className="py-2 mt-4" />
                </Carousel>
            </div>
            <div
                id="carouselScrollDownIndicator"
                className="absolute bottom-0 items-center justify-center hidden w-12 h-12 cursor-pointer xl:flex xl:pb-4"
                onClick={handleScrollDownClick}
                ref={ref}
            >
                {isLottileVisible ? (
                    <Lottie
                        animationData={scrollDown}
                        loop={true}
                        autoplay={true}
                        className="cursor-pointer"
                    />
                ) : null}
            </div>
            <div
                className="absolute flex items-center justify-center rounded-full -z-1 bg-home-page-primary-bg-sm md:bg-home-page-primary-bg-md opacity-40 md:opacity-100 h-[304px] w-[304px] md:h-[590px] md:w-[590px] left-[-156px] top-[-140px]  md:left-[-440px] md:top-[-270px] xl:left-[-270px]"
                tabIndex={-1}
            >
                <div className="h-[192px] w-[192px] md:h-[366px] md:w-[366px] xl:h-[310px] xl:w-[310px] rounded-full bg-basicWhite"></div>
            </div>
            <div
                className="absolute hidden md:flex items-center justify-center rounded-full -z-1 bg-home-page-secondary-bg-sm md:bg-home-page-secondary-bg-md xl:bg-home-page-secondary-bg-xl opacity-60 md:opacity-100 h-[370px] w-[370px] md:h-[590px] md:w-[590px]  right-[-240px] bottom-[-100px] md:right-[-420px] md:top-[-290px] md:rotate-0 xl:right-[-270px] xl:top-[-280px] !-rotate-45"
                tabIndex={-1}
            >
                <div className="h-[258px] w-[258px]  md:h-[366px] md:w-[366px] xl:h-[310px] xl:w-[310px] rounded-full bg-basicWhite"></div>
            </div>
            <div
                class="-z-1 absolute w-[736px] h-[736px] top-[10%] left-[5%] opacity-[10%] bg-home-page-banner-primary-bg rounded-full blur-[100px] hidden xl:block"
                tabIndex={-1}
            />
            <div
                class="-z-1 absolute w-[736px] h-[736px] top-[-25%] right-[20%] opacity-[10%] bg-home-page-banner-secondary-bg rounded-full blur-[100px] hidden xl:block"
                tabIndex={-1}
            />
        </div>
    );
};

export default CarouselBanner;
